@charset "utf-8";
@import "~bootstrap/dist/css/bootstrap.css";
html {
    height: 100%;
    background-color: black;
    font-size: 100%;
}

body {
    /* TODO CHECK IMPACT min-height instead of height */
    height: 100%;
    max-width: 1080px;
    margin: auto;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
}

/*#entete {*/
/*    max-width: 1080px;*/
/*    height: 173px;*/
/*    background-image: url('/images/entete.jpg');*/
/*    width: 100%;*/
/*    background-position: center;*/
/*    margin: auto;*/

/*}*/
#menu-small-device{
    z-index: 1;
    background-color: white;
    width: 100%;
}

#menu-small-device ul {
    width: 100%;
}

#menu-small-device li {
    text-align: center;
    list-style: none;
    padding: 10px;
    margin: 0;
    width: 100%;
}

#menu {
    width: 100%;
    /*max-width: 1080px;*/

    height: 40px;
    margin: auto;
    /*margin-bottom: 2rem;*/
    z-index: 1;
    background-color: white;

}

#menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

#menu li {
    text-align: center;
    flex: 1;
    list-style: none;
    padding: 10px;
    margin: 0;
}

#menu li.active {
    border-bottom: rgba(13, 88, 166, 0.2) 2px solid;
}

#menu li:hover {
    background-color: rgba(13, 88, 166, 0.2);
}

#menu li:hover a {
    position: relative;
    bottom: 2px;
}

#menu a {
    color: #000;
    text-decoration: none;
    width: 100%;
    display: block;
}

#menu a:hover {
    color: #FF9900
}

#menu a:active {
    text-decoration: none;
    color: #FF9900
}

#menu a:visited {
    text-decoration: none;
    color: #000
}


.main-container {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.main-content {
    width: 95%;
    /*max-width: 940px;*/
    font-size: 0.9rem;
    text-align: justify;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: row;
}
#corps_filmo {
    padding-top: 2rem;
}
#corps_filmo a:hover{
    text-decoration: none;
}
#corps_filmo .main-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-gap: 1rem;
    text-align: center;
}

.movie:hover {
    transform: scale(1.1);
}

/*BIO*/
/*#corps_bio .main-content {*/
/*    font-size: 0.9rem;*/
/*    text-align: justify;*/
/*    margin: auto;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background-color: white;*/
/*    flex-direction: row;*/
/*}*/
.portrait-container {
    align-self: center;
}

/*.text-container {*/
/*    flex: 3;*/
/*}*/

/*FILMO*/
/*#corps_filmo {*/
/*    width: 100%;*/
/*    margin: auto;*/
/*    background-color: #FFFFFF;*/
/*    text-align: center;*/
/*}*/


#corps_filmo img {
    width: 150px;
    height: 200px;
}

.filmtitre {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}

#corps_cv {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    background: white;
    display: block
}
#corps_cv a:hover{
    text-decoration: none;
}
.cv_section, .cv_sous-section {
    margin-bottom: 0.5rem;
}
.border_cv_section::after {
    width: 66%;
    border-bottom: 1px solid rgba(13, 88, 166, 0.2);
    content: "";
    position: relative;
    left: 16%;
    height: 1px;
    display: inline-block;
}
.cv_border-sous-section.collapse.show::after {
    width: 65%;
    border-bottom: 1px solid rgba(13, 88, 166, 0.2);
    content: "";
    position: relative;
    left: 33%;
    height: 1px;
    display: inline-block;
}
#corps_cv h2 {
    margin-bottom: 4px;
    font-size: 1.5rem;
}
#corps_cv h3 {
    font-size:1.3rem;
    margin-bottom: 2px;
}
.btn_chevron h2, .btn_chevron h3{
    display: inline-flex;
}
.btn_chevron.collapsed h2::before,.btn_chevron.collapsed h3::before {
    margin-right: 15px;
    content: '+';
}
.btn_chevron h2::before, .btn_chevron h3::before {
    margin-right: 15px;
    content: 'x';
}
.cv_film_poste_highlight {
    font-weight: bold;
}
.cv_film_note {
    font-size: small;
    color: #666;
    line-height: 1.4;
    padding: 0;
    margin-bottom: 0.6rem;
}
.light-text{
    color: #888;
}


/*.cv-row-year{*/
/*    width: 3rem;*/
/*    display: inline-block;*/
/*}*/

/*#corps_films .main-content {*/
/*    display: flex;*/
/*}*/

/*#corps_films .part1 {*/
/*    flex: 1;*/
/*    text-align: center;*/
/*}*/

/*#corps_films .part2 {*/
/*    flex: 2;*/
/*}*/

#corps_films .film-info {
    padding-bottom: 2em;
}

#corps_films .film-text {
    margin-top: 2em;
}

/*#corps_films .film-info, #corps_films .film-iframe {*/
/*    display: inline-block;*/
/*    width: 49%;*/
/*}*/

#corps_films {
    font-size: 12px;
    background: white;
    margin-top: 2.5em;
    min-height: calc(100% - 416px);
}

#corps_films .film-pictures {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    /*position: absolute;*/
    /*bottom: 0;*/
    width: 100%;
}

#corps_films .affiche {
    display: flex;
    align-items: center;
    justify-content: center;
}


#corps_films .affiche img{
    /*width: auto;*/
    /*height: 100%;*/
    flex: 0.2 0 auto;
    /*TODO ??*/
    align-self: baseline;
}

.film-iframe {
    display: flex;
    justify-content: end;
}

.titre {
    text-align: center;
    font-size: larger;
}

.centre {
    text-align: center;
}

.centre_petit {
    text-align: center;
    font-size: smaller;
    color: #666666;
}

.gauche {
    text-align: right;
}

.droite {
    text-align: left;
}

.detail {
    text-align: left;
    font-size: smaller;
    color: #666666;
}

.note {
    color: #888888;
}

.intertype {
    margin-top: -10px
}

#pieddepage {
    max-width: 1080px;
    height: 173px;
    background-image: url('/images/pieddepage.jpg');
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: center;
    margin: auto;
    width: 100%;
}

#pieddepage div {
    height: 15px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: end;
    justify-content: center;
}

#pieddepage p {
    background-color: rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    height: 15px;
    width: 100%;
    text-align: center;
}

#pieddepage a {
    color: #fff
}

#pieddepage a:hover {
    color: #FF9900
}

#pieddepage a:active {
    color: #FF9900
}

a:visited {
    color: #fff
}


iframe {
    text-align: right;
    margin: auto;
}


/*ROW AND GRID*/
.row {
    width: 100%;
}


/*LINK*/
a {
    color: #000
}

a:hover {
    color: #FF9900
}

a:active {
    color: #FF9900
}

a:visited {
    color: #000
}

.selections{
    text-align: left;
    font-style: italic;
}

.displayUnder767{
    display: none;
}
/*@media (max-width: 1200px) {*/
/*    body {*/
/*        width: 90%;*/
/*    }*/
/*}*/
/*TODO change with 768 when photo resized*/
@media (max-width: 720px) {
    /*body{*/
    /*    width: 100%;*/
    /*}*/

    .displayOver767{
        display: none;
    }
    .displayUnder767{
        display: block;
    }

    /*#corps_bio .main-content {*/
    /*    flex-direction: column;*/
    /*    padding-top: 30px;*/
    /*}*/

    /*.main-content {*/
    /*    width: 100%;*/
    /*    padding: 15px 20px;*/
    /*}*/


    /*#corps_films .main-content {*/
    /*    display: block;*/
    /*}*/


    /*#corps_films .film-info, #corps_films .film-iframe {*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    padding-bottom: 2em;*/
    /*}*/

    /*#corps_films .film-text {*/
    /*    width: 100%;*/
    /*    padding-bottom: 2em;*/
    /*}*/

    /*#corps_films .film-pictures {*/
    /*    display: block;*/
    /*}*/

    /*#corps_films .film-pictures .vignette {*/
    /*    width: 95%;*/
    /*    margin: auto;*/
    /*    margin-bottom: auto;*/
    /*    height: auto;*/
    /*    margin-bottom: 1em;*/
    /*}*/
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
body {
    /* TODO CHECK IMPACT min-height instead of height */
    height: 100%;
    width: 100%;
    margin: auto;
}
#entete {
    width: 100%;
    height: 100%;
    background-image: url('/images/layout/360-740.jpg');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}
#pieddepage {
    height: 30px;
    width: 100%;
    background-image: none;
    background-color: black;
}
.cv_sous-section {
    padding-left: 1rem;
}
.cv_section_subtitle{
    color: #888;
    font-size: small;
    text-align: left;
    width: 100%;
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    body {
        max-width: 768px;
    }
    #entete {
        max-width: 768px;
        height: 123px;
        background-image: url('/images/layout/entete_resolution768.jpg');
    }
    #pieddepage {
        max-width: 768px;
        height: 124px;
        background-image: url('/images/layout/pieddepage_resolution768.jpg');
    }
    .main-container {
        min-height: calc(100% - 312px);
    }
    iframe {
        text-align: right;
    }
    .cv_sous-section {
        padding-left: 0;
    }
}

/* Medium devices (desktops, 992px and up) TODO CHANGE WIDTH */
@media (min-width: 992px) {
    body {
        max-width: 992px;
    }

    #entete {
        max-width: 992px;
        height: 159px;
        background-image: url('/images/layout/entete_resolution992.jpg');
    }

    #pieddepage {
        max-width: 992px;
        height: 160px;
        background-image: url('/images/layout/pieddepage_resolution992.jpg');
    }

    .main-container {
        min-height: calc(100% - 384px);
    }
    .cv_section_subtitle{
        color: #888;
        text-align: center;
        font-size: small;
        /*display: inline-block;*/
        width: 66%;
        margin: 0 auto 10px auto;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    body {
        max-width: 1200px;
    }
    #entete {
        max-width: 1200px;
        height: 193px;
        background-image: url('/images/layout/entete_resolution1200.jpg');
    }
    #pieddepage {
        max-width: 1200px;
        height: 193px;
        background-image: url('/images/layout/pieddepage_resolution1200.jpg');
    }
    .main-container {

        min-height: calc(100% - 425px); /*450 = pieddepage.height + entete.height + ???? */
    }
}

/*!* Large devices (large desktops, 1920 and up) *!*/
/*@media (min-width: 1920px) {*/
/*    body {*/
/*        max-width: 1920px;*/
/*    }*/
/*    #entete {*/
/*        max-width: 1920px;*/
/*        height: 308px;*/
/*        background-image: url('/images/layout/entete_resolution1920.jpg');*/
/*    }*/
/*    #pieddepage {*/
/*        max-width: 1920px;*/
/*        height: 308px;*/
/*        background-image: url('/images/layout/pieddepage_resolution1920.jpg');*/
/*    }*/
/*    .main-container {*/
/*        min-height: calc(100% - 680px);*/
/*    }*/
/*}*/
